import React from 'react';
import PropType from 'prop-types';
import { Route, withRouter, matchPath } from 'react-router-dom';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';

import { Header, Body, Footer } from 'components/layout';

import { ROUTE_GENERATE_KEYS, ROUTE_ORG } from '../../../constant/routes';

import styles from './signedInViewer.style';

export const UserContext = React.createContext(() => {});

export const withUser = Component => props => (
  <UserContext.Consumer>
    {({ user, selectedOrganization, superUserOrgs, switchOrganization }) => (
      <Component
        user={user}
        selectedOrganization={selectedOrganization}
        superUserOrgs={superUserOrgs}
        switchOrganization={switchOrganization}
        {...props}
      />
    )}
  </UserContext.Consumer>
);

export const SELECTED_ORG_KEY = 'SELECTED_ORG';

// const loadSelectedOrgId = () => localStorage.getItem(SELECTED_ORG_KEY);

const saveSelectedOrgId = orgId => {
  localStorage.setItem(SELECTED_ORG_KEY, orgId);
};

export class SignedInViewer extends React.Component {
  static propTypes = {
    classes: PropType.object.isRequired,
    user: PropType.object.isRequired,
    component: PropType.func.isRequired,
    history: PropType.object.isRequired,
    location: PropType.object.isRequired,
    superUserOrgs: PropType.object,
    withoutHeader: PropType.bool
  };

  static defaultProps = {
    withoutHeader: false,
    superUserOrgs: null
  };

  constructor(props) {
    super(props);

    const [firstOrg] = props.user.organizations.nodes;
    // const savedSelectedOrgId = loadSelectedOrgId();
    const { location } = props;
    const match = matchPath(location.pathname, {
      path: '/organization/:orgId/*',
      exact: true,
      strict: false
    });

    const organizationId = match?.params.orgId;

    const selectedOrganization =
      (organizationId &&
        props.user.organizations.nodes.find(
          o => o.rowId === organizationId[0]
        )) ||
      firstOrg;

    if (selectedOrganization) saveSelectedOrgId(selectedOrganization.rowId);

    this.state = {
      selectedOrganization
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    this.update(prevProps);
  }

  update = prevProps => {
    const {
      location,
      history,
      user: { account: { signingKey }, name, email, rowId }
    } = this.props;

    // Set user context if first load (no prevProps) or any of the context values
    // have changed.
    if (
      prevProps === undefined ||
      name !== prevProps.user.name ||
      email !== prevProps.user.email ||
      rowId !== prevProps.user.rowId
    ) {
      // apm.setUserContext({
      //   id: rowId,
      //   username: name,
      //   email
      // });
    }

    // If the user is missing a key this means that they need to generate them,
    // so redirect to key generation page. This should only happen when a user
    // sign up is interupted.
    if (!signingKey && location.pathname !== ROUTE_GENERATE_KEYS) {
      history.push(ROUTE_GENERATE_KEYS);
    }
  };

  switchOrganization = org => {
    const { history, location } = this.props;
    const path = location.pathname;

    this.setState({ selectedOrganization: org });
    const menuLocation = path.split('/')[3];
    const newPath = `${ROUTE_ORG}/${org.rowId}/${menuLocation}`;
    history.push(newPath);
  };

  render() {
    const {
      classes,
      user,
      superUserOrgs,
      withoutHeader,
      component: Component,
      ...props
    } = this.props;
    const { selectedOrganization } = this.state;

    return (
      <UserContext.Provider
        value={{
          user,
          selectedOrganization,
          superUserOrgs,
          switchOrganization: this.switchOrganization
        }}
      >
        <Route
          {...props}
          render={p => {
            if (withoutHeader) return <Component {...p} />;
            return (
              <div className={classes.root}>
                <div className={classes.header}>
                  <Header user={user} />
                </div>
                <div className={classes.body}>
                  <Body user={user}>
                    <Component {...p} />
                  </Body>
                  <Footer />
                </div>
              </div>
            );
          }}
        />
      </UserContext.Provider>
    );
  }
}

export default createFragmentContainer(
  compose(withRouter, injectSheet(styles))(SignedInViewer),
  {
    superUserOrgs: graphql`
      fragment signedInViewer_superUserOrgs on OrganizationsConnection {
        nodes {
          id
          rowId
          name
          avatar
        }
      }
    `,
    user: graphql`
      fragment signedInViewer_user on User {
        rowId
        firstName
        lastName
        name
        email
        phone
        avatar
        canUpdate
        isSuperuser
        account {
          id
          rowId
          encryptionKey {
            rowId
            publicKey
          }
          signingKey {
            rowId
            publicKey
            privateKey {
              decrypted
              passwordProtected
            }
          }
        }
        organizations {
          nodes {
            id
            rowId
            name
            avatar
            canUpdate
          }
        }
        teams {
          totalCount
          nodes {
            id
            rowId
            name
            avatar
            canUpdate
            organization {
              id
              rowId
              name
              avatar
            }
          }
        }
      }
    `
  }
);
