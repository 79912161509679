import React, { useState, useRef, useEffect } from 'react';
import {
  Collection,
  CollectionItem,
  Pushbutton,
  Pushtext,
  Avatar,
  UiconClipboard,
  UiconKeyHorizontal,
  AvatarGroup,
  TextEllipsis
} from '@stratumn/atomic';
import injectSheet from 'react-jss';
import { withRouter, generatePath, Link } from 'react-router-dom';
import PropType from 'prop-types';
import compose from 'lodash.flowright';
import classNames from 'classnames';

import { withUser } from 'wrappers';

import {
  ROUTE_EDIT_PROFILE,
  ROUTE_PASSWORD_UPDATE_COMPONENT,
  ROUTE_TEAM
} from 'constant/routes';

import nameToInitials from 'utils/nameToInitials';
import addToClipBoard from 'utils/addToClipBoard';

import styles from './myProfile.style';

import RegenerateKeyModal from './regenerateKeyModal';
import DecryptAndCopyKeyModal from './decryptKeyModal';

const MAX_TEAMS = 15;

export function MyProfile(props) {
  const [showRegenModal, setShowRegenModal] = useState(false);
  const [showDecryptModal, setShowDecryptModal] = useState(false);
  const [copiedPublicKey, setClipboardPublicKey] = useState(false);
  const [copiedPrivateKey, setClipboardPrivateKey] = useState(false);
  const [showAllTeams, setShowAllTeams] = useState(false);
  const pkTimeoutRef = useRef(null);
  const skTimeoutRef = useRef(null);

  useEffect(
    () => () => {
      if (pkTimeoutRef.current) {
        clearTimeout(pkTimeoutRef.current);
      }
      if (skTimeoutRef.current) {
        clearTimeout(skTimeoutRef.current);
      }
    },
    []
  );

  // If the current user did not generate any signing key, abort in
  // favor of the "generate key" form
  if (!props.user || !props.user.account || !props.user.account.signingKey) {
    return null;
  }

  const toggleRegenModal = () => setShowRegenModal(!showRegenModal);
  const toggleDecryptModal = async (success = false) => {
    setShowDecryptModal(!showDecryptModal);
    if (success) {
      await setClipboardPrivateKey(true);
      skTimeoutRef.current = setTimeout(() => {
        setClipboardPrivateKey(false);
      }, 1500);
    }
  };

  const {
    classes,
    user: {
      name,
      email,
      phone,
      avatar,
      account: { signingKey: { publicKey, privateKey } },
      organizations: { nodes: orgs },
      teams: { nodes: teams }
    },
    history
  } = props;

  const displayedTeams = showAllTeams ? teams : teams.slice(0, MAX_TEAMS);

  const copyPublicKey = async key => {
    addToClipBoard(key);
    await setClipboardPublicKey(true);
    pkTimeoutRef.current = setTimeout(() => {
      setClipboardPublicKey(false);
    }, 1500);
  };

  const copyPrivateKey = async key => {
    addToClipBoard(key);
    await setClipboardPrivateKey(true);
    skTimeoutRef.current = setTimeout(() => {
      setClipboardPrivateKey(false);
    }, 1500);
  };

  const onPrivateKeyCopy = async key => {
    const { decrypted, passwordProtected } = key;

    if (passwordProtected) {
      toggleDecryptModal();
      return;
    }
    copyPrivateKey(decrypted);
  };

  const goToUpdatePwdPage = () =>
    props.history.push(ROUTE_PASSWORD_UPDATE_COMPONENT);

  const renderTeamBadge = (team, index) => {
    const org = orgs.find(o => o.name === team.organization.name);

    if (org) {
      return (
        <Link
          key={index}
          to={generatePath(ROUTE_TEAM, {
            id: team.rowId,
            orgId: org.rowId
          })}
          className={classNames(classes.teamBadge, classes.teamBadgeLink)}
          title={team.name}
        >
          <AvatarGroup
            initials={nameToInitials(team.name)}
            size={25}
            src={team.avatar}
          />
          <TextEllipsis text={team.name} maxWidth={100} />
        </Link>
      );
    }

    return (
      <div
        key={index}
        className={classNames(classes.teamBadge, classes.teamBadgeNoLink)}
        title={team.name}
      >
        <AvatarGroup
          initials={nameToInitials(team.name)}
          size={25}
          src={team.avatar}
        />
        <TextEllipsis text={team.name} maxWidth={100} />
      </div>
    );
  };

  return (
    <>
      <div className={classes.profileHeaderRow}>
        <Avatar src={avatar} size={56} />
        <Pushbutton secondary onClick={() => history.push(ROUTE_EDIT_PROFILE)}>
          Edit Profile
        </Pushbutton>
      </div>
      <Collection>
        <div className={classes.collectionItemFirstChild}>
          <CollectionItem label="name" value={name} idle={!name} />
        </div>
        <CollectionItem label="email" value={email} idle={!email} />
        <CollectionItem
          label="phone"
          value={phone || 'Not Added'}
          idle={!phone}
        />

        <div className={classes.signingKeyWrapper}>
          <div className={classes.signingKeyHeader}>
            <div className={classes.signingKeyTitleWrapper}>
              <div className={classes.signingKeyTitle}>Account Password</div>
            </div>
          </div>
          <div className={classes.pushTextCustom}>
            <Pushtext onClick={goToUpdatePwdPage}>Update</Pushtext>
          </div>
        </div>

        <div className={classes.signingKeyWrapper}>
          <div className={classes.signingKeyHeader}>
            <div className={classes.signingKeyTitleWrapper}>
              <div className={classes.signingKeyTitle}>Signing Keys</div>
              <UiconKeyHorizontal size={27} />
            </div>

            <div className={classes.buttonClipboard}>
              <Pushtext
                suffix={<UiconClipboard size={27} checked={copiedPublicKey} />}
                onClick={() => copyPublicKey(publicKey)}
                mute={!copiedPublicKey}
              >
                {copiedPublicKey ? 'Copied' : 'Copy'}{' '}
                <span className={classes.bold}>public</span> key
              </Pushtext>
            </div>

            <Pushtext
              suffix={<UiconClipboard size={27} checked={copiedPrivateKey} />}
              onClick={() => onPrivateKeyCopy(privateKey)}
              mute={!copiedPrivateKey}
            >
              {copiedPrivateKey ? 'Copied' : 'Copy'}{' '}
              <span className={classes.bold}>private</span> key
            </Pushtext>
          </div>
          <div className={classes.pushTextCustom}>
            <Pushtext onClick={toggleRegenModal}>Regenerate</Pushtext>
          </div>
        </div>
        <div className={classes.signingKeyWrapper}>
          <div className={classes.signingKeyHeader}>
            <div className={classes.signingKeyTitleWrapper}>
              <div className={classes.signingKeyTitle}>
                My Teams{' '}
                <span>
                  (If you are not part of the organization you will not be able
                  to access the team)
                </span>
              </div>
            </div>
          </div>
          <div className={classes.teamsList}>
            {displayedTeams.map((team, index) => renderTeamBadge(team, index))}
            {teams.length > MAX_TEAMS && (
              <div className={classes.showMore}>
                <button onClick={() => setShowAllTeams(!showAllTeams)}>
                  {showAllTeams ? 'Voir moins' : 'Voir plus...'}
                </button>
              </div>
            )}
          </div>
        </div>
      </Collection>

      {showRegenModal && <RegenerateKeyModal toggleModal={toggleRegenModal} />}
      {showDecryptModal && (
        <DecryptAndCopyKeyModal
          toggleModal={toggleDecryptModal}
          encryptedKey={privateKey.decrypted}
        />
      )}
    </>
  );
}

MyProfile.propTypes = {
  classes: PropType.object.isRequired,
  user: PropType.object.isRequired
};

export default compose(withRouter, withUser, injectSheet(styles))(MyProfile);
