import React from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';
import { withRouter } from 'react-router-dom';

import { withGqlClient, withUser } from 'wrappers';

import Organization from './organization';
import LoadingState from '../loadingState';

const query = graphql`
  query organizationQuery($orgRowId: BigInt!) {
    organizationByRowId(rowId: $orgRowId) {
      ...organization_organization
    }
  }
`;

export const Renderer = ({ error, props: data }) => {
  if (error) return <div>error!</div>;
  if (!data) return <LoadingState fullHeight />;
  const { organizationByRowId } = data;
  return <Organization organization={organizationByRowId} />;
};

Renderer.propTypes = {
  error: PropType.object,
  props: PropType.object
};

Renderer.defaultProps = {
  error: undefined,
  props: undefined
};

export const UserOrganization = props => {
  const { environment, selectedOrganization } = props;
  const variables = {};

  // There are some users that have unrealistic data so we need to account
  // for them to not crash the UI.
  if (!selectedOrganization || !selectedOrganization.rowId) {
    return null;
  }

  const { rowId } = selectedOrganization;
  variables.orgRowId = rowId;

  // We need to make sure that the location props are passed to child components correctly.
  // https://github.com/ReactTraining/react-router/blob/v4.2.2/packages/react-router/docs/guides/blocked-updates.md
  const RendererWithRouter = withRouter(Renderer);

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={RendererWithRouter}
    />
  );
};

UserOrganization.propTypes = {
  environment: PropType.object.isRequired,
  selectedOrganization: PropType.object
};

UserOrganization.defaultProps = {
  selectedOrganization: null
};

export default compose(withUser, withGqlClient)(UserOrganization);
