export default theme => ({
  profileHeaderRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 21
  },
  pushTextCustom: {
    color: theme.grey1,
    fontSize: 14,
    fontWeight: 'normal',
    height: 29,
    lineHeight: '29px',
    marginBottom: 10
  },
  collectionItemFirstChild: {
    borderWidth: '1px 0 0 0',
    borderStyle: 'solid',
    borderColor: theme.grey6
  },
  signingKeyWrapper: {
    marginTop: 30,
    borderWidth: '0 0 1px 0',
    borderStyle: 'solid',
    borderColor: theme.grey6
  },
  signingKeyHeader: {
    display: 'flex'
  },
  signingKeyTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },
  signingKeyTitle: {
    paddingRight: 6,
    fontSize: 16,
    fontWeight: 600,
    '& > span': {
      fontWeight: 100,
      fontSize: '12px',
      fontStyle: 'italic'
    }
  },
  buttonClipboard: {
    paddingRight: 20
  },
  bold: {
    fontWeight: 700
  },
  teamsList: {
    display: 'flex',
    margin: '10px 0',
    gap: '10px',
    flexWrap: 'wrap',
    fontSize: '12px'
  },
  teamBadge: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '5px 8px',
    color: 'inherit',
    backgroundColor: theme.grey8,
    borderRadius: '5px',
    textDecoration: 'none'
  },
  teamBadgeLink: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  teamBadgeNoLink: {
    cursor: 'not-allowed'
  },
  showMore: {
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});
