/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type signedInViewer_user$ref = any;
export type privateRouteUserQueryVariables = {||};
export type privateRouteUserQueryResponse = {|
  +me: ?{|
    +isSuperuser: ?boolean,
    +$fragmentRefs: signedInViewer_user$ref,
  |}
|};
export type privateRouteUserQuery = {|
  variables: privateRouteUserQueryVariables,
  response: privateRouteUserQueryResponse,
|};
*/


/*
query privateRouteUserQuery {
  me {
    isSuperuser
    ...signedInViewer_user
    id
  }
}

fragment signedInViewer_user on User {
  rowId
  firstName
  lastName
  name
  email
  phone
  avatar
  canUpdate
  isSuperuser
  account {
    id
    rowId
    encryptionKey {
      rowId
      publicKey
      id
    }
    signingKey {
      rowId
      publicKey
      privateKey {
        decrypted
        passwordProtected
        id
      }
      id
    }
  }
  organizations {
    nodes {
      id
      rowId
      name
      avatar
      canUpdate
    }
  }
  teams {
    totalCount
    nodes {
      id
      rowId
      name
      avatar
      canUpdate
      organization {
        id
        rowId
        name
        avatar
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSuperuser",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicKey",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "privateRouteUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "signedInViewer_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "privateRouteUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Key",
                "kind": "LinkedField",
                "name": "encryptionKey",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v6/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Key",
                "kind": "LinkedField",
                "name": "signingKey",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PrivateKey",
                    "kind": "LinkedField",
                    "name": "privateKey",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "decrypted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "passwordProtected",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationsConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamsConnection",
            "kind": "LinkedField",
            "name": "teams",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "privateRouteUserQuery",
    "operationKind": "query",
    "text": "query privateRouteUserQuery {\n  me {\n    isSuperuser\n    ...signedInViewer_user\n    id\n  }\n}\n\nfragment signedInViewer_user on User {\n  rowId\n  firstName\n  lastName\n  name\n  email\n  phone\n  avatar\n  canUpdate\n  isSuperuser\n  account {\n    id\n    rowId\n    encryptionKey {\n      rowId\n      publicKey\n      id\n    }\n    signingKey {\n      rowId\n      publicKey\n      privateKey {\n        decrypted\n        passwordProtected\n        id\n      }\n      id\n    }\n  }\n  organizations {\n    nodes {\n      id\n      rowId\n      name\n      avatar\n      canUpdate\n    }\n  }\n  teams {\n    totalCount\n    nodes {\n      id\n      rowId\n      name\n      avatar\n      canUpdate\n      organization {\n        id\n        rowId\n        name\n        avatar\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c05f82c120db3617f2cd33171955c311';

module.exports = node;
