import React from 'react';
import PropTypes from 'prop-types';
import { LoaderTraceLogo } from '@stratumn/atomic';
import injectSheet from 'react-jss/lib/injectSheet';
import styles from './loadingState.style';

const LoadingState = ({ classes, fullHeight }) => (
  <div
    className={classes.loadingContainer}
    {...fullHeight && { style: { height: '100vh' } }}
  >
    <LoaderTraceLogo />
  </div>
);

LoadingState.propTypes = {
  classes: PropTypes.object.isRequired,
  fullHeight: PropTypes.bool
};

export default injectSheet(styles)(LoadingState);
